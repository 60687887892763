import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  // Navigate,
  // Link,
} from "react-router-dom";

import Footer from './Components/Footer';

import CustomerHome from './Pages/Customer/Home';
import LandingPage from './Pages/Customer/LandingPage';
import Terms from './Pages/Customer/Terms';
import SalesHome from './Pages/Sales/Home';
import SiteUsage from './Pages/Customer/SiteUsage';


function App() {

  return (
    <div className="w-full h-screen flex flex-col">

      <BrowserRouter>
        <div className="flex-1">
          <Routes>
          <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/home" element={<CustomerHome />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/site-usage" element={<SiteUsage />} />
            <Route path="/admin" element={<SalesHome />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
      <script async data-id="101475644" src="//static.getclicky.com/js"></script>
      <noscript><p><img alt="Clicky" width="1" height="1" src="//in.getclicky.com/101475644ns.gif" /></p></noscript>
    </div>
  );
}

export default App;
